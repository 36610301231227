import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { InputTextModule } from 'primeng/inputtext';
import { StorageService } from './modules/shared/services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './modules/shared/components/loader/loader.service';
import { EditorModule } from 'primeng/editor';
import { PanelModule } from 'primeng/panel';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordModule } from 'primeng/password';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { MessagesModule } from 'primeng/messages';
import { AuthorizeGatewayComponent } from './pages/authorize-gateway/authorize-gateway.component';
import { AuthorizeSuccessPageComponent } from './pages/authorize-success-page/authorize-success-page.component';
import { AuthorizedFailurePageComponent } from './pages/authorized-failure-page/authorized-failure-page.component';
import { AuthorizeCardDetailsComponent } from './pages/authorize-card-details/authorize-card-details.component';
import { AuthorizedPendingPaymentComponent } from './pages/authorized-pending-payment/authorized-pending-payment.component';
import { BookingByCustomerComponent } from './pages/booking-by-customer/booking-by-customer.component';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BookingsModule } from './modules/bookings/bookings.module';
import { BookingFareForcustomerComponent } from './pages/booking-fare-forcustomer/booking-fare-forcustomer.component';
import { QrCodeForAppComponent } from './pages/qr-code-for-app/qr-code-for-app.component';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    TrackMyLocationComponent,
    LoginComponent,
    PrivacyPolicyComponent,
    AuthorizeGatewayComponent,
    AuthorizeSuccessPageComponent,
    AuthorizedFailurePageComponent,
    AuthorizeCardDetailsComponent,
    AuthorizedPendingPaymentComponent,
    BookingByCustomerComponent,
    BookingFareForcustomerComponent,
    QrCodeForAppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    InputTextModule,
    EditorModule,
    DialogModule,
    ButtonModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    MultiSelectModule,
    AppRoutingModule,
    FieldsetModule,
    OverlayPanelModule,
    CalendarModule,
    FileUploadModule,
    ConfirmDialogModule,
    PasswordModule,
    SharedModule.forRoot(),
    // BookingsModule.forRoot(),
    MessagesModule,
    DropdownModule,
    AutoCompleteModule,
  ],
  providers: [StorageService, ConfirmationService, LoaderService, NotificationsService, MessageService, ToastModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule
    };
  }
  constructor() {
  }
}
