import { Component, OnInit } from '@angular/core';
import { QrCodeService } from 'app/modules/shared/services/qr-code.service';

@Component({
  selector: 'app-qr-code-for-app',
  templateUrl: './qr-code-for-app.component.html',
  styleUrls: ['./qr-code-for-app.component.scss']
})
export class QrCodeForAppComponent implements OnInit {
  qrImageShortLink: string = ''; 
  version: string = '1.2'; 
  deviceType: string = 'android'; 
  qrImage: any;

  constructor(private qrCodeService: QrCodeService) {}
  
  ngOnInit() {
    this.passengerQrCode();
    this.driverQrCode();
  }

  passengerQrCode(){
    this.qrCodeService.getPassengerQr('passenger').subscribe(
      (response) => {
        this.qrImage = response.versionDetails.qrImage;
        this.qrImageShortLink = response.versionDetails.qrImageShortLink;
      },
      (error) => {
        console.error('Error fetching version details:', error);
      }
    );
  }

  driverQrCode(){
    this.qrCodeService.getDriverQr('driver').subscribe(
      (response) => {
        this.qrImage = response.versionDetails.qrImage;
        this.qrImageShortLink = response.versionDetails.qrImageShortLink;
      },
      (error) => {
        console.error('Error fetching version details:', error);
      }
    );
  }

}
