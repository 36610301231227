import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

 private apiUrl = 'https://fandsdevapi.mi2.in/api/tenantdev00001/secure/version/details';
 private prependApiUrl(url: string): string {
     return AppSettings.BASE_URL + '/' + AppSettings.TENANT + url;
 }

  constructor(private http: HttpClient) { }

  getPassengerQr(appType: string): Observable<any> {
    const params = {
      appType: appType
    };
    return this.http.get(this.prependApiUrl('/version/details'), { params });
  }

  getDriverQr(appType: string): Observable<any> {
    const params = {
      appType: appType
    };
    return this.http.get(this.prependApiUrl('/version/details'), { params });
  }
  
}
